<template>
  <v-select
    :value="item.itemsPerPage"
    :label="$t('Lignes par page')"
    :items="rowsPerPage"
    item-text="label"
    item-value="value"
    outlined
    dense
    hide-details
    @input="item.itemsPerPage = parseInt($event, 10)"
  ></v-select>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'

export default {
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const rowsPerPage = ref([
      { value: 25, label: i18n.t('25') },
      { value: 50, label: i18n.t('50') },
      { value: 100, label: i18n.t('100') },
      { value: 300, label: i18n.t('300') },
      { value: 500, label: i18n.t('500') },
      { value: 1000, label: i18n.t('1000') },
      { value: -1, label: i18n.t('Tous') },
    ])

    const item = computed({
      get: () => props.options,
      set: value => emit('update:object', value),
    })

    return {
      item,
      rowsPerPage,
    }
  },
}
</script>
